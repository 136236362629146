import React from "react";
import tw from "twin.macro";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PageLayout, CTA, Button, DotMatrix } from "../components";

const Brands = ({
  data: {
    strapiDsrBrands: { brandsList, ...pageProps },
  },
}) => {
  return (
    <PageLayout {...pageProps} flourish={1} mainStyles={tw`bg-gray-50`}>
      <section tw="relative mt-6 py-8 prose prose-lg text-gray-500! mx-auto">
        <div tw="mx-auto">
          {/* <DotMatrix side="right" /> */}
          <div tw="flex flex-wrap space-y-6 mx-4">
            {brandsList.map(({ description, logo }) => (
              <div
                key={description.substr(0, 5)}
                tw="bg-white p-4 rounded-lg shadow-lg w-full"
              >
                <div tw="flex flex-wrap space-y-4 md:space-y-0 items-center">
                  <div tw="px-4 w-full md:w-4/12">
                    <GatsbyImage
                      image={getImage(logo.localFile)}
                      tw="w-full transition-colors duration-200 hover:opacity-75"
                      imgStyle={{ marginTop: 0 }}
                      alt={logo.alternativeText}
                      objectFit="contain"
                    />
                  </div>
                  <div tw="px-4 w-full md:w-8/12">
                    <p>{description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div tw="flex items-center justify-center w-full my-6!">
          <Button colour="secondary-dark" text="Visit Us" to="/locations" />
        </div>
      </section>
      <CTA />
    </PageLayout>
  );
};

export default Brands;

export const query = graphql`
  query brandsPageQuery {
    strapiDsrBrands {
      brandsList {
        description
        logo {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 250)
            }
          }
        }
      }
      content
      subtitle
      intro
      title
    }
  }
`;
